import { useMemo } from 'react';
import { Col, Row } from 'antd';
import Image from 'next/image';
import { userModelContainer } from '@/store/userModel';
import { dashboardSitePrefixPath } from '@/utils';
import { track } from '@/utils/track';
import { HotTag, NewTag } from '../../Icon';
import { hotServices, isOpenKie, linkToConsole, newServices } from '../constants';
import styles from './index.module.less';
import classNames from 'classnames';
import SceneList from '../SceneList';

const RenderTitle = ({ iconPath, title }) => {
  return (
    <div className={styles.title}>
      <Image src={iconPath} width={16} height={16} alt="" />
      <span>{title}</span>
    </div>
  );
};

export const RenderItem = ({ list }) => {
  if (!Array.isArray(list)) return null;
  const { userInfo } = userModelContainer.useContainer();

  const onClick = (item) => {
    if (item.position === 'header') {
      track({ name: '按钮点击', keyword: `导航栏【体验中心】`, product_type: item.name });
    }
  };

  return (
    <>
      {list.map((cardItem) => {
        // 未登录并且有体验中心的服务
        if (cardItem.exper_center_status === 1 && !userInfo?.account) {
          return (
            <a
              className={styles.link}
              target="_blank"
              href={`/experience/${cardItem.service}`}
              rel=""
              key={cardItem.service}
              onClick={() => onClick(cardItem)}
            >
              {cardItem.name || ''}
              {cardItem.newTag && <span className={styles.tag}>{<NewTag />}</span>}
              {cardItem.hotTag && <span className={styles.tag}>{<HotTag />}</span>}
            </a>
          );
        } else {
          let url = `/market/detail/${cardItem.service}`;
          const console_url = `${dashboardSitePrefixPath}${cardItem.interaction_uri}?service=${cardItem.service}`;
          if (linkToConsole(cardItem)) {
            url = userInfo?.account
              ? console_url
              : `/user/login?redirect=${encodeURIComponent(console_url)}`;
          } else if (userInfo?.account && cardItem.interaction !== -1) {
            url = console_url;
          }
          if (isOpenKie(cardItem)) {
            url = console_url;
          }
          return (
            <a
              type="link"
              className={styles.link}
              target="_blank"
              href={url}
              rel=""
              key={cardItem.service}
              onClick={() => onClick(cardItem)}
            >
              {cardItem.name || ''}
              {cardItem.newTag && <span className={styles.tag}>{<NewTag />}</span>}
              {cardItem.hotTag && <span className={styles.tag}>{<HotTag />}</span>}
            </a>
          );
        }
      })}
    </>
  );
};

export const RenderRow = ({ data: arr }) => {
  const data = useMemo(() => {
    if (!Array.isArray(arr)) return {};
    return arr.reduce((acc, item) => {
      if (Array.isArray(item.children)) {
        item.children.forEach((child) => {
          child.newTag = newServices.includes(child.service);
          child.hotTag = hotServices.includes(child.service);
        });
      }
      return { ...acc, [item.id]: item };
    }, {});
  }, [arr]);
  return (
    <Row className={styles['gutter-wrap']} gutter={40}>
      <Col span={6}>
        {!!data[1]?.children?.length && (
          <div className={styles['card-item']}>
            {/* 大模型 */}
            <RenderTitle title={data[1].title} iconPath="/images/experience/popover/icon-llm.svg" />
            <RenderItem list={data[1].children} />
          </div>
        )}
        {!!data[2]?.children?.length && (
          <div className={styles['card-item']}>
            {/* 通用识别 */}
            <RenderTitle
              title={data[2].title}
              iconPath="/images/experience/popover/icon-text.svg"
            />
            <RenderItem list={data[2].children} />
          </div>
        )}
        {!!data[6]?.children?.length && (
          <div className={styles['card-item']}>
            {/* 篡改检测 */}
            <RenderTitle title={data[6].title} iconPath="/images/experience/popover/icon-ai.svg" />
            <RenderItem list={data[6].children} />
          </div>
        )}
        {!!data[3]?.children?.length && (
          <div className={styles['card-item']}>
            {/* 图像处理 */}
            <RenderTitle
              title={data[3].title}
              iconPath="/images/experience/popover/icon-image.svg"
            />
            <RenderItem list={data[3].children} />
          </div>
        )}
      </Col>
      {!!data[7]?.children?.length && (
        <Col span={6}>
          <div className={styles['card-item']}>
            {/* 卡证 */}
            <RenderTitle
              title={data[7].title}
              iconPath="/images/experience/popover/icon-card.svg"
            />
            <RenderItem list={data[7].children} />
          </div>
        </Col>
      )}
      {!!data[4]?.children?.length && (
        <Col span={6}>
          <div className={styles['card-item']}>
            {/* 票据 */}
            <RenderTitle
              title={data[4].title}
              iconPath="/images/experience/popover/icon-bill.svg"
            />
            <RenderItem list={data[4].children} />
          </div>
          <div className={styles['card-item']}>
            {/* 格式转换 */}
            <RenderTitle title={data[5].title} iconPath="/images/experience/popover/icon-ai.svg" />
            <RenderItem list={data[5].children} />
          </div>
        </Col>
      )}
      <Col span={6}>
        <div className={styles['card-item']}>
          {/* 企业智能应用 */}
          <div className={styles.title}>
            <span className={styles.sceneTitle}>企业智能应用</span>
          </div>
          <SceneList />
        </div>
      </Col>
    </Row>
  );
};
